<template>
  <header>
    <h1>{{headerTitle}}</h1>
    <div class="subheading">{{headerSubTitle}}</div>
    <router-link to="/" v-if="this.$router.history.current['path'] !== '/'">Home</router-link>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
    headerTitle: {
      type: String,
      default: 'ALFI'
    },
    headerSubTitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
header {
  background-color: #5B676F;
  height: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-transform: uppercase;
}

.subheading {
  letter-spacing: 1px;
}

h1 {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 0;
  font-size: 60px;
  letter-spacing: 12px;
  line-height: 1;
}

a {
  margin-top: 10px;
  line-height: 1.1;
  font-size: 14px;
  font-weight: 500;
  font-family: brandon-grotesque, sans-serif;
  text-transform: uppercase;
  text-underline-offset: 3px;
  color: #a6b9c5;
}

a:hover,
a:focus {
  color: #d1e2ed;
}
</style>
