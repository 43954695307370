import Vue from 'vue'
import VueRouter from 'vue-router'
import Page from '../views/Page.vue'
import Calculator from '../views/Calculator.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/calculator',
    name: 'Calculator',
    component: Calculator
  },
  {
    path: '*',
    name: 'Page',
    component: Page
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      selector: '#app',
      behavior: 'smooth'
    }
  },
})

export default router
