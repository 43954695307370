<template>
  <div class="markup" v-html="resource.htmlContent"></div>
</template>

<script>
export default {
  name: 'Markup',
  props: {
    resource: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>
.markup {
  padding: 40px 20px 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: center;
}
</style>
