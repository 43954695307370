<template>
  <router-link class="parent" :to="parent">
    <span class="arrow">
      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5625 13.9062C7.29883 13.9062 7.06445 13.8184 6.88867 13.6426L1.26367 8.01758C0.882812 7.66602 0.882812 7.05078 1.26367 6.69922L6.88867 1.07422C7.24023 0.693359 7.85547 0.693359 8.20703 1.07422C8.58789 1.42578 8.58789 2.04102 8.20703 2.39258L3.25586 7.34375L8.20703 12.3242C8.58789 12.6758 8.58789 13.291 8.20703 13.6426C8.03125 13.8184 7.79688 13.9062 7.5625 13.9062Z" fill="#201E20"/>
      </svg>
    </span>
    <span class="title">
      {{pageTitle}}
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'Parent',
  props: {
    parent: {
      type: String,
      default: null
    },
    pageTitle: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
a {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 500;
  line-height: 1.1;
  min-height: 60px;
  padding: 10px;
  border-bottom: 1px solid #dadada;
  background-color: #EEEEF0;
}

a:hover {
  background-color: #dcdcdc;
}

span {
  display: block;
}

.title {
  flex-grow: 1;
  padding-top: 2px;
  text-align: center;
  padding-right: 20px;
}

.arrow {
  width: 20px;
  flex-shrink: 0;
  margin-left: 10px;
}

.arrow svg {
  display: block;
}
</style>
