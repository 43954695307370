<template>
  <nav>
    <ul>
      <li v-for="(item, index) in items" :key="`navItem${index}`">
        <router-link :to="item.urlSlug">
          <span class="icon" v-if="item.icon">
            <img :src="item.icon" />
          </span>
          <span class="title">
            {{item.title}}
          </span>
          <span class="arrow">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.4375 13.2617C1.17383 13.2617 0.939453 13.1738 0.763672 12.998C0.382812 12.6465 0.382812 12.0312 0.763672 11.6797L5.71484 6.69922L0.763672 1.74805C0.382812 1.39648 0.382812 0.78125 0.763672 0.429688C1.11523 0.0488281 1.73047 0.0488281 2.08203 0.429688L7.70703 6.05469C8.08789 6.40625 8.08789 7.02148 7.70703 7.37305L2.08203 12.998C1.90625 13.1738 1.67188 13.2617 1.4375 13.2617Z" fill="currentColor"/>
            </svg>
          </span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  display: block;
}

a {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 500;
  line-height: 1.1;
  min-height: 60px;
  padding: 10px;
  border-bottom: 1px solid #EEEEF0;
}

a:hover {
  background-color: #EEEEF0;
}

span {
  display: block;
}

.title {
  flex-grow: 1;
  padding-top: 2px;
}

.arrow {
  width: 20px;
  flex-shrink: 0;
  margin-left: 10px;
}

.arrow svg {
  display: block;
}

.icon {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}
</style>
