<template>
  <div class="page">
    <div class="spinner" v-if="loading"></div>
    <div class="page-wrapper" v-else>
      <app-header
          :header-title="page.headerTitle"
          :header-sub-title="page.headerSubTitle"
      ></app-header>
      <div class="contents">
        <parent :parent="page.parentSlug" :page-title="page.pageTitle" v-if="page && page.parentSlug && page.pageTitle" />
        <navigation :items="page.navItems" v-if="page && page.navItems && page.navItems.length" />
        <extra-navigation v-if="$route.fullPath === '/'" />
        <div class="page-content" v-if="page.resourceGroups && page.resourceGroups.length !== 0">
          <div class="group" v-for="(group, index) in page.resourceGroups" :key="`group${index}`">
            <h2>{{group.groupName}}</h2>
            <div class="resources">
              <div class="resource" v-for="(resource, rIndex) in group.resources" :key="`group${index}_resource${rIndex}`">
                <lazy-video v-if="resource.type === 'youtube'" :resource="resource" />
                <pdf-link v-if="resource.type === 'pdf'" :resource="resource" />
                <markup v-if="resource.type === 'html'" :resource="resource" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        {{ page.footerText }}
      </footer>
    </div>
  </div>
</template>

<script>
import service from "@/service/service";
import Navigation from "@/components/Navigation";
import Parent from "@/components/Parent";
import { gsap } from '@/plugins/gsap'
import ExtraNavigation from "@/components/ExtraNavigation";
import Markup from "@/components/Markup";
import LazyVideo from "../components/LazyVideo";
import PdfLink from "../components/PdfLink";
import AppHeader from '@/components/AppHeader.vue';
export default {
  name: 'Page',
  components: {AppHeader, PdfLink, LazyVideo, Markup, ExtraNavigation, Parent, Navigation},
  data () {
    return {
      loading: true,
      page: null,
      timeline: null,
    }
  },
  mounted() {
    service.getPage(this.$route.fullPath).then((res) => {
      this.page = res.data
      this.loading = false
    })
  },
  watch: {
    $route (to, from) {
      this.loading = true
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length

      service.getPage(this.$route.fullPath).then((res) => {
        this.page = res.data
        this.loading = false

        if (to.path === '/' || toDepth < fromDepth) {
          this.timeline = gsap.timeline();
          this.timeline.from(".contents", {x: -300, autoAlpha:0, duration: 0.2});
          // this.timeline.from("nav li", {y: 10, autoAlpha:0, duration: 0.1, stagger: 0.05});
        } else {
          this.timeline = gsap.timeline();
          this.timeline.from(".contents", {x: 300, autoAlpha:0, duration: 0.2});
          // this.timeline.from("nav li", {y: 10, autoAlpha:0, duration: 0.1, stagger: 0.05});
        }

      })
    }
  }
}
</script>

<style scoped>
header {
  background-color: #5B676F;
  height: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-transform: uppercase;
}

footer {
  background-color: #EEEEF0;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #4D545E;
}

.subheading {
  letter-spacing: 1px;
}

h1 {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 0;
  font-size: 60px;
  letter-spacing: 12px;
  line-height: 1;
}

.page-content {
  padding: 0 20px;
  padding-bottom: 50px;
}

.group {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  border: 1px solid #EEE;
}

.group h2 {
  background-color: #EEE;
  padding: 10px 20px;
  margin: 0;
  font-family: brandon-grotesque, sans-serif;
  text-transform: uppercase;
  user-select: none;
}

.resources {
  padding: 20px;
}

.resource {
  margin-top: 20px;
}

.resource:first-child {
  margin-top: 0;
}

.page {
  display: flex;
  flex-direction: column;
}

.page-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.contents {
  flex-grow: 1;
}
</style>
