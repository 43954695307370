<template>
  <div class="pdf-link">
    <a :href="resource.pdfUrl" target="_blank">{{resource.title}}</a>
  </div>
</template>

<script>
export default {
  name: 'PdfLink',
  props: {
    resource: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
