<template>
  <div class="lazy-video">
    <LazyYoutubeVideo :src="`https://www.youtube.com/embed/${resource.youtubeId}`" />
    <div class="title">{{resource.title}}</div>
  </div>
</template>

<script>
import 'vue-lazy-youtube-video/dist/style.css'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
export default {
  name: 'LazyVideo',
  components: {
    LazyYoutubeVideo,
  },
  props: {
    resource: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>
.title {
  background-color: #EEE;
  padding: 10px;
}
</style>
