<template>
  <div class="calculator">
    <div class="blinds" v-for="(blind, index) in blinds" :key="`blind${index}`">
      <div class="blind">
        <div class="title">
          Blind {{index + 1}}
          <div class="remove" @click="$delete(blinds, index)">
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 0.875C3.33984 0.875 0 4.24414 0 8.375C0 12.5352 3.33984 15.875 7.5 15.875C11.6309 15.875 15 12.5352 15 8.375C15 4.24414 11.6309 0.875 7.5 0.875ZM10.3125 9.07812H4.6875C4.27734 9.07812 3.98438 8.78516 3.98438 8.375C3.98438 7.99414 4.27734 7.67188 4.6875 7.67188H10.3125C10.6934 7.67188 11.0156 7.99414 11.0156 8.375C11.0156 8.78516 10.6934 9.07812 10.3125 9.07812Z" fill="currentColor"/>
            </svg>
            Remove
          </div>
        </div>
        <div class="inputs">
          <div class="input">
            <div class="label">Width</div>
            <input type="number" v-model.number="blinds[index].width">
          </div>
          <div class="input">
            <div class="label">Drop</div>
            <input type="number" v-model.number="blinds[index].drop">
          </div>
          <div class="input">
            <div class="label">Fabric Weight gsm</div>
            <input type="number" v-model.number="blinds[index].fabricWeight">
          </div>
          <div class="input">
            <div class="label">Bottom Bar g/m</div>
            <input type="number" v-model.number="blinds[index].bottomBar">
          </div>
          <div class="input">
            <div class="label">Total weight</div>
            <input type="number" disabled :value="blindWeights[index]">
          </div>
        </div>
      </div>
    </div>
    <div class="add-blind">
      <button @click="addBlind">
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6562 6.375C12.6562 6.90234 12.2168 7.3418 11.7188 7.3418H7.5V11.5605C7.5 12.0586 7.06055 12.4688 6.5625 12.4688C6.03516 12.4688 5.625 12.0586 5.625 11.5605V7.3418H1.40625C0.878906 7.3418 0.46875 6.90234 0.46875 6.375C0.46875 5.87695 0.878906 5.4668 1.40625 5.4668H5.625V1.24805C5.625 0.720703 6.03516 0.28125 6.5625 0.28125C7.06055 0.28125 7.5 0.720703 7.5 1.24805V5.4668H11.7188C12.2168 5.4375 12.6562 5.87695 12.6562 6.375Z" fill="currentColor"/>
        </svg>
        Add a blind
      </button>
    </div>
    <div class="results">
      <div class="blind">
        <div class="title">Results</div>
        <div class="inputs">
          <div class="input">
            <div class="label">Total Weight KG's</div>
            <input type="number" disabled :value="totalWeight">
          </div>
          <div class="input">
            <div class="label">Motor NM Required on 37mm</div>
            <input type="number" disabled :value="motor37">
          </div>
          <div class="input">
            <div class="label">Motor NM Required on 50mm</div>
            <input type="number" disabled :value="motor50">
          </div>
          <div class="input">
            <div class="label">Motor NM Required on 65mm</div>
            <input type="number" disabled :value="motor65">
          </div>
          <div class="input">
            <div class="label">Motor NM Required on 70mm</div>
            <input type="number" disabled :value="motor70">
          </div>
          <div class="input">
            <div class="label">Motor NM Required on 78mm</div>
            <input type="number" disabled :value="motor78">
          </div>
        </div>
      </div>
    </div>
    <div class="fabrics">
      <div class="key">
        <div class="title">Fabrics</div>
        <div class="item">
          <div class="label">Elements</div>
          <div class="value">450 GSM</div>
        </div>
        <div class="item">
          <div class="label">Plaza</div>
          <div class="value">395 GSM</div>
        </div>
        <div class="item">
          <div class="label">Baltic Block</div>
          <div class="value">460 GSM</div>
        </div>
        <div class="item">
          <div class="label">Baltic Trans</div>
          <div class="value">250 GSM</div>
        </div>
        <div class="item">
          <div class="label">Neotech</div>
          <div class="value">420 GSM</div>
        </div>
        <div class="item">
          <div class="label">Zen</div>
          <div class="value">370 GSM</div>
        </div>
        <div class="item">
          <div class="label">EcoView</div>
          <div class="value">430 GSM</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Calculator',
  data () {
    return {
      blinds: [{
        width: 0,
        drop: 0,
        fabricWeight: 0,
        bottomBar: 0
      }]
    }
  },
  methods: {
    addBlind () {
      this.blinds.push({
        width: 0,
        drop: 0,
        fabricWeight: 0,
        bottomBar: 0
      })
    }
  },
  computed: {
    blindWeights () {
      let weights = []
      for (let i = 0; i < this.blinds.length; i++) {
        const blind = this.blinds[i];
        const w = (blind.width / 1000) * (blind.drop / 1000) * (blind.fabricWeight / 1000) + (blind.bottomBar * blind.width / 1000000)
        weights.push(w)
      }
      return weights
    },
    totalWeight () {
      let w = 0
      for (let i = 0; i < this.blindWeights.length; i++) {
        const blindWeight = this.blindWeights[i];
        w += blindWeight
      }
      return w
    },
    motor37 () {
      return (this.totalWeight * 10) * (0.037 / 2) * 1.1
    },
    motor50 () {
      return (this.totalWeight * 10) * (0.05 / 2) * 1.1;
    },
    motor65 () {
      return (this.totalWeight * 10) * (0.065 / 2) * 1.1;
    },
    motor70 () {
      return (this.totalWeight * 10) * (0.07 / 2) * 1.1;
    },
    motor78 () {
      return (this.totalWeight * 10) * (0.078 / 2) * 1.1;
    }
  }
}
</script>

<style scoped>
.calculator {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.fabrics {
  margin-top: 30px;
  font-size: 12px;
}

.fabrics .title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.fabrics .item {
  border-top: 1px solid #ededed;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
}

.fabrics .item .label {
  width: 100px;
}

.blind {
  margin-top: 20px;
}

.inputs {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid #a6aeb6;
  border-right: 1px solid #a6aeb6;
  border-bottom: 1px solid #a6aeb6;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px 5px 10px;
  border-top: 1px solid #a6aeb6;
}

.input .label {
  width: 50%;
  padding-right: 20px;
  font-size: 12px;
}

.input input {
  width: 50%;
  font-size: 16px;
  padding: 5px;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 500;
  border: 1px solid #a6aeb6;
  border-radius: 3px;
}

input:disabled {
  background-color: #fff;
  font-family: 'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif;
  border: 1px solid #FFF;
  border-radius: 3px;
  font-size: 14px;
  color: #201E20;
  opacity: 1;
}

.blind .title {
  background-color: #5B676F;
  color: #FFF;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 500;
  padding: 20px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
}

.blind .title .remove {
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  font-family: Arial, sans-serif;
  line-height: 1;
  cursor: pointer;
}

.blind .title .remove svg {
  display: block;
  margin-right: 5px;
}

.add-blind {
  margin-top: 30px;
}

.add-blind button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 14px 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s ease;
  color: #FFF;
  background-color: #5B676F;
}

.add-blind button svg {
  display: block;
  margin-right: 10px;
}
</style>
